* {
    box-sizing: border-box;
}

html, body, #root, #pageContainer, .df-body {
	height: 100%;
	max-height: 100%;
}

/* body {
	overflow: hidden;
} */

.df-body {
	width: 100% !important;
	max-width: 100% !important;
	overflow: auto;
}

.df-body .df-body {
	height: initial !important;
	max-height: initial !important;
	width: initial !important;
	max-width: 100% !important;
}